import React, { useState, useEffect } from "react";
import { Footer } from "../types/footer";
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import Confetti from 'react-dom-confetti';

type FooterProps = Footer;

export default function FooterComponent (props: FooterProps) {

  const [confetti, setConfetti] = useState(false)
  const [emojiIndex, setEmojiIndex] = useState(0)

  const config = {
    angle: 90,
    spread: 126,
    startVelocity: 70,
    elementCount: 100,
    dragFriction: 0.1,
    duration: 3000,
    stagger: 0,
    
  };

  const format = require('string-format')

  const heartEmojis = ['❤️', '💙', '💜', '💛', '💚', '💖', '🧡']

  useEffect( () => {
    setTimeout(() => setConfetti(false), 3000)
  }, [confetti])

  useBottomScrollListener( () => setConfetti(true))

  return (
    <footer>
      <div className="container" style={{ userSelect: 'none' }}>
        <div className="footer-content" onClick={() => setEmojiIndex((emojiIndex + 1) % heartEmojis.length)}>
          <p>
            {props.line1}
            <br />
              {format(props.line2, { emoji: heartEmojis[emojiIndex] })}
          </p>
        </div>
        <Confetti config={config} active={confetti}/>
      </div>
    </footer>
  );
};
