import React from "react";
import { Work } from "../types/work";

type WorkCardProps = Work;

export const WorkCard: React.FC<WorkCardProps> = (props: WorkCardProps) => {
  return (
    <div className="row">
      <div className="col-1 hide-small" />
      <a href={props.url} title="Company url">
        <div className="col-11 menu-item">
          <img src={require("../img/" + props.img)} className="logo" alt="" />
          <h3>{props.title}</h3>
          <h6 className="label-hide">{props.location}</h6>
          <br />
          <h6 className="font-light label-hide">{props.date}</h6>
        </div>
      </a>
    </div>
  );
};
