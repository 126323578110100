import React from "react";
import { Education } from "../types/education";

type EducationCardProps = Education;

export const EducationCard: React.FC<EducationCardProps> = (
  props: EducationCardProps
) => {
  return (
    <div className="row">
      <div className="col-1 hide-small"></div>
      <a href={props.url} title={props.name}>
        <div className="col-11 menu-item education-card">
          <img src={require("../img/" + props.icon)} className="logo" alt="" />
          <h3>{props.name}</h3>
          <h6 className="label-hide">{props.location}</h6>
          <br />
          <h6 className="label-hide">{props.degree}</h6>
          <br />
          <h6 className="font-light label-hide">{props.year}</h6>
        </div>
      </a>
    </div>
  );
};
