import React, { Fragment } from "react";
import { LandingTitle } from "../components/landingTitle";
import { LandingSubtitle } from "../components/landingSubtitle";
import { Landing } from "../types/landing";

type LandingProps = Landing;

export const LandingComponent: React.FC<LandingProps> = (
  props: LandingProps
) => {
  return (
    <Fragment>
      <div className="title-content">
        <LandingTitle name={props.name} />
        <LandingSubtitle {...props} />
      </div>
    </Fragment>
  );
};
