import React, { Fragment } from "react";
import { WorkCard } from "../components/workCard";
import { Work } from "../types/work";

interface WorkProps {
  work: Work[];
}
export const WorkComponent: React.FC<WorkProps> = (props: WorkProps) => {
  return (
    <Fragment>
      <div className="contents-grid work-container">
        <div className="row">
          <div className="col-1 hide-small">
            <div className="line line-small"></div>
          </div>
          <div className="col-11">
            <h2>Work Experience</h2>
          </div>
        </div>
      </div>
      {props.work.map((workPlace, index) => {
        return <WorkCard key={index} {...workPlace} />;
      })}
    </Fragment>
  );
};
