import React from "react";
import { EducationCard } from "../components/educationCard";
import { Education } from "../types/education";

interface EducationProps {
  education: Education[];
}

export const EducationComponent: React.FC<EducationProps> = (
  props: EducationProps
) => {
  return (
    <div className="contents-grid">
      <div className="row">
        <div className="col-1 hide-small">
          <div className="line line-small"></div>
        </div>
        <div className="col-11">
          <h2>Education</h2>
        </div>
      </div>
      {props.education.map((educationPlaces, index) => {
        return <EducationCard key={index} {...educationPlaces} />;
      })}
    </div>
  );
};
