import { Work } from "./types/work";
import { Education } from "./types/education";
import { Landing } from "./types/landing";
import { Footer } from "./types/footer";

export interface AppData {
  landing: Landing;
  work: Work[];
  education: Education[];
  footer: Footer;
}

export const APP_DATA: AppData = {
  landing: {
    name: "Jim",
    project:
      "Flick, a chrome extension that lets you watch Netflix with friends!",
    projectUrl: "https://flick.jimyang.xyz/",
    employer: "Gem",
    employerUrl: "https://gem.com"
  },
  work: [
    {
      title: "Gem",
      location: "San Francisco, CA",
      date: "August 2021 - Present",
      url: "https://gem.com",
      img: "gem.png"
    },
    {
      title: "Shopify",
      location: "Remote",
      date: "May 2020 - Dec 2020",
      url: "https://www.shopify.ca/",
      img: "shopify.png"
    },
    {
      title: "Nextroll",
      location: "San Francisco, CA",
      date: "Jan 2020 - April 2020",
      url: "https://www.nextroll.com/",
      img: "nextroll.png"
    },
    {
      title: "Shareworks",
      location: "Calgary, AB",
      date: "May 2019 - Aug 2019",
      url: "https://shareworks.com",
      img: "shareworks.png"
    },
    {
      title: "SAP Hybris",
      location: "Montreal, QC",
      date: "Sept 2018 - Dec 2018",
      url: "https://sap.com",
      img: "sap.png"
    },
    {
      title: "QuickTapSurvey",
      location: "Toronto, ON",
      date: "Jan 2018 - Apr 2018",
      url: "https://quicktapsurvey.com",
      img: "qts.png"
    },
    {
      title: "Marsh and McLennan",
      location: "Toronto, ON",
      date: "May 2017 - Aug 2017",
      url: "https://mmc.com",
      img: "mmc.png"
    }
  ],
  education: [
    {
      name: "University of Waterloo",
      url: "https://ece.uwaterloo.ca",
      icon: "uw.svg",
      location: "Waterloo, ON",
      degree: "Computer Engineering",
      year: "(4B) Sept 2016 - Present"
    }
  ],
  footer: {
    line1: "Thanks for stopping by.",
    line2: "Made with {emoji} in Waterloo, ON"
  }
};
