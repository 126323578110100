import React from "react";

interface LandingSubtitleProps {
  project: string;
  projectUrl: string;
  employer: string;
  employerUrl: string;
}

export const LandingSubtitle: React.FC<LandingSubtitleProps> = (
  props: LandingSubtitleProps
) => {
  return (
    <div className="opening-p">
      <div className="row">
        <div className="col-8">
          <p>
            <span className="strong">I am currently working on </span>{" "}
            <a href={props.projectUrl}>{props.project}</a>
          </p>
          <p>
            <span className="strong">I currently work at </span>
            <a href={props.employerUrl}> {props.employer}</a>
          </p>
        </div>
      </div>
    </div>
  );
};
