import React from "react";
import ReactTooltip from 'react-tooltip'

interface LandingTitleProps {
  name: string;
}

export const LandingTitle: React.FC<LandingTitleProps> = (
  props: LandingTitleProps
) => {
  return (
    <div className="title">
      <div className="row">
        <div className="col-10">
          <h1>
            Hi, I'm {props.name}
            <br />
            I'm a <a href="https://github.com/Jim-Yang">software</a> developer
            and an <a data-tip="Can't legally say 'engineer' unless you're a P.Eng!" href="#noop">aspiring</a> engineer
          </h1>
        </div>
      </div>
      <ReactTooltip place="left" type="dark" effect="solid"/>
    </div>
  );
};
