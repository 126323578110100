import React from "react";
import { LandingComponent } from "./landing";
import { WorkComponent } from "./work";
import "./App.css";
import { APP_DATA } from "../data";
import FooterComponent from "../components/footer";
import { EducationComponent } from "./education";

const App: React.FC = () => {
  return (
    <div className="container super-container">
      <LandingComponent {...APP_DATA.landing} />
      <WorkComponent work={APP_DATA.work} />
      <EducationComponent education={APP_DATA.education} />
      <FooterComponent {...APP_DATA.footer} />
    </div>
  );
};

export default App;
